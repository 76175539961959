<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="企业图片" name="first">
        <el-card shadow="never">
          <div class="mb-16 flex">
            <el-button type="primary" @click="baocun">保存</el-button>
            <div class="ml-16 size-12">*格式png、jpg，大小不超过2MB。</div>
          </div>
          <el-upload
            class="banner-img"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleSuccessBanner"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="企业介绍" name="second">
        <el-card shadow="never">
          <div class="mb-16">
            <el-button type="primary" @click="baocun">保存</el-button>
          </div>
          <quill-editor
            style="height: 100%"
            v-model="ruleForm.companyIntroduce"
            ref="myQuillEditor"
            :options="editorOption"
          />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="产品介绍" name="three">
        <el-card shadow="never">
          <div class="mb-16">
            <el-button type="primary" @click="baocun">保存</el-button>
          </div>
          <quill-editor
            style="height: 100%"
            v-model="ruleForm.goodsIntroduce"
            ref="myQuillEditor"
            :options="editorOption"
          />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="产品介绍1" name="7">
        <el-card shadow="never">
          <div class="mb-16">
            <el-button type="primary" @click="baocun">保存</el-button>
          </div>
          <quill-editor
            style="height: 100%"
            v-model="ruleForm.goodsIntroduce1"
            ref="myQuillEditor"
            :options="editorOption"
          />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="企业视频" name="four">
        <el-card shadow="never">
          <div class="mb-16">
            <el-button type="primary" @click="videoDialogVisible = true"
              >添加视频</el-button
            >
          </div>
          <div class="xian mb-16"></div>
          <div
            class="mb-16"
            v-for="i in ruleForm.companyVideoModelList"
            :key="i.videoId"
          >
            <div class="mb-16">
              <p>{{ i.describes }}</p>
            </div>
            <div class="flex ag-str">
              <video
                :src="i.url"
                width="300"
                controls
                style="max-height: 240px"
              >
                <source src="movie.mp4" type="video/mp4" />
              </video>
              <div class="flex ml-16">
                <el-button type="primary" @click="editvideo(i)">编辑</el-button>
                <el-button type="primary" @click="daleteVideo(i.videoId)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
      </el-tab-pane>

      <el-tab-pane label="企业招聘" name="five">
        <el-card shadow="never">
          <div class="mb-16 flex">
            <el-button
              type="primary"
              @click="(PostDialogVisible = true), (postFrom = {})"
              >添加职位</el-button
            >
            <el-button type="primary" @click="hrPhoneDialogVisible = true"
              >HR电话</el-button
            >
          </div>
          <commonTable
            :tableData="tableData"
            :loading="loading"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :currentPage="currentPage"
            :total="total"
          >
            <template v-slot:table>
              <el-table-column align="center" type="index" label="序号" />
              <el-table-column
                prop="position"
                align="center"
                label="招聘职位"
              />
              <el-table-column
                prop="recruitAddress"
                align="center"
                label="招聘城市"
              />
              <el-table-column prop="count" align="center" label="工作经验">
                <div slot-scope="scope">
                  {{ getJob(scope.row.experience) }}
                </div>
              </el-table-column>
              <el-table-column prop="count" align="center" label="学历要求">
                <div slot-scope="scope">
                  {{ getXueli(scope.row.education) }}
                </div>
              </el-table-column>
              <el-table-column align="center" label="招聘薪资">
                <div slot-scope="scope">
                  {{ getMoney(scope.row.minSalary, scope.row.maxSalary) }}
                </div>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <div slot-scope="scope">
                  <el-button
                    slot="reference"
                    type="text"
                    @click="daleteJob(scope.row.recruitId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
            </template>
          </commonTable>
        </el-card>
      </el-tab-pane>

      <el-tab-pane label="企业信息" name="six">
        <el-card shadow="never">
          <el-form label-width="78px">
            <el-form-item label="联系电话" prop="title">
              <div class="flex">
                <div class="w-500">
                  <el-input v-model="ruleForm.phone"></el-input>
                </div>

                <el-button class="ml-16" type="primary" @click="baocun"
                  >修改</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="公司地址" prop="title">
              <div class="w-500" style="position: relative">
                <el-input
                  v-model="ruleForm.address"
                  disabled
                  placeholder="如：广东省深圳市龙岗区中兴路19号布吉侨联大厦3层303"
                ></el-input>
              </div>
              <div class="flex mt-6">
                <div class="address-box w-500">
                  <el-input
                    id="suggestId"
                    v-model="address"
                    placeholder="请输入地址"
                  ></el-input>
                  <div id="baidumap"></div>
                </div>
                <el-button
                  class="ml-16"
                  type="primary"
                  @click="baocun1"
                  v-if="address"
                  >修改</el-button
                >
                <div v-else class="ml-16">
                  <el-popconfirm
                    title="您未填写地址，点击修改将会清空地址记录。是否确认修改？"
                    @confirm="baocun1"
                  >
                    <el-button slot="reference" type="primary">修改</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="企业网站" prop="title">
              <div class="flex">
                <div class="w-500">
                  <el-input v-model="ruleForm.officialWebsite"></el-input>
                </div>

                <el-button class="ml-16" type="primary" @click="baocun"
                  >修改</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加视频 -->
    <el-dialog
      title="添加视频"
      :visible.sync="videoDialogVisible"
      @close="(videoDialogVisible = false), (videoFrom = {}), (fileList1 = [])"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form label-width="78px">
        <el-form-item label="视频名称" prop="title">
          <el-input v-model="videoFrom.describes"></el-input>
        </el-form-item>
        <el-form-item label="视频封面" prop="title">
          <el-upload
            class="banner-img"
            :class="{ disabled: uploadDisabled }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleSuccessCover"
            :on-remove="handleRemove"
            :file-list="fileList1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传视频" prop="title">
          <el-upload
            class="avatar-uploader"
            :action="$store.state.uploadingUrl"
            :show-file-list="false"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :before-upload="beforeAvatarUploadVideo"
          >
            <video
              v-if="videoFrom.url"
              :src="videoFrom.url"
              width="100%"
              height="100%"
              controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过10M
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            (videoDialogVisible = false), (videoFrom = {}), (fileList1 = [])
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addVideo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加职位 -->
    <el-dialog
      title="添加职位"
      :visible.sync="PostDialogVisible"
      @close="(PostDialogVisible = false), (postFrom = {})"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form label-width="78px">
        <el-form-item label="招聘职位">
          <el-input v-model="postFrom.position" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="招聘城市">
          <el-input
            v-model="postFrom.recruitAddress"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作经验">
          <el-select v-model="postFrom.experience" placeholder="请选择">
            <el-option
              v-for="item in experienceArray"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历要求" prop="title">
          <el-select v-model="postFrom.education" placeholder="请选择">
            <el-option
              v-for="item in educatArray"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招聘薪资" prop="title">
          <div class="flex">
            <el-select v-model="postFrom.minSalary" placeholder="最低">
              <el-option
                v-for="i in 20"
                :key="i"
                :label="i"
                :value="i"
                :disabled="
                  i > postFrom.maxSalary - 1 && postFrom.maxSalary !== ''
                "
              >
              </el-option>
            </el-select>
            <p class="mlr-10">-</p>
            <el-select v-model="postFrom.maxSalary" placeholder="最高">
              <el-option
                v-for="i in 20"
                :key="i"
                :label="i"
                :value="i"
                :disabled="i < postFrom.minSalary + 1"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(PostDialogVisible = false), (postFrom = {})"
          >取 消</el-button
        >
        <el-button type="primary" @click="addPost">确 定</el-button>
      </span>
    </el-dialog>
    <!-- HR电话 -->
    <el-dialog
      title="HR电话"
      :visible.sync="hrPhoneDialogVisible"
      @close="(hrPhoneDialogVisible = false), (hrPhone = '')"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form label-width="78px">
        <el-form-item label="HR电话">
          <el-input v-model="hrPhone" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(hrPhoneDialogVisible = false), (hrPhone = {})"
          >取 消</el-button
        >
        <el-button type="primary" @click="addHrPhone">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addWebsiteInfo,
  selectWebsiteInfo,
  insertCompanyVideo,
  delCompanyVideo,
  selectCompanyRecruit,
  addCompanyRecruit,
  delCompanyRecruit,
  addHrPhone,
} from "@/api/companyManage";
import commonTable from "@/components/common/commonTable";
import { mapState } from "vuex";

import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请输入";
quillConfig.modules.imageDrop = true;
let ac;
export default {
  components: {
    quillEditor,
    commonTable,
  },
  data() {
    return {
      address_type: true,
      videoDialogVisible: false,
      editorOption: quillConfig,
      hrPhoneDialogVisible: false,
      hrPhone: "",
      ruleForm: {},
      fileList: [],
      fileList1: [],
      activeName: "first", //tabs页
      videoFrom: {},
      isUpdate: true,
      PostDialogVisible: false,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      /*学历*/
      educatArray: [
        { title: "全部", id: 1 },
        { title: "初中及以下", id: 2 },
        { title: "中专/中技", id: 3 },
        { title: "高中", id: 4 },
        { title: "大专", id: 5 },
        { title: "本科", id: 6 },
        { title: "硕士", id: 7 },
        { title: "博士", id: 8 },
      ],
      /*经验*/
      experienceArray: [
        { title: "应届生", id: 1 },
        { title: "1年以内", id: 2 },
        { title: "1-3年", id: 3 },
        { title: "3-5年", id: 4 },
        { title: "5-10年", id: 5 },
        { title: "10年以上", id: 6 },
        { title: "全部", id: 7 },
      ],
      postFrom: {},
      address: "",
    };
  },
  created() {
    this.getInfo();
  },
  computed: {
    ...mapState(["routerArr"]),
    uploadDisabled() {
      return this.fileList1.length !== 0;
    },
  },
  methods: {
    addHrPhone() {
      addHrPhone({ recruitPhone: this.hrPhone }).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功！");
          this.hrPhoneDialogVisible = false;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    getJob(i) {
      let str = "";
      this.experienceArray.forEach((el) => {
        if (el.id == i) {
          str = el.title;
        }
      });
      return str;
    },
    getXueli(i) {
      let str = "";
      this.educatArray.forEach((el) => {
        if (el.id == i) {
          str = el.title;
        }
      });
      return str;
    },
    daleteJob(id) {
      delCompanyRecruit({ recruitId: id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
          this.currentPage = 1;
          this.getRecruit();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    addPost() {
      // if(!this.postFrom.minSalary){

      // }
      this.postFrom.minSalary = this.postFrom.minSalary * 100 * 1000;
      this.postFrom.maxSalary = this.postFrom.maxSalary * 100 * 1000;
      addCompanyRecruit(this.postFrom).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功！");
          this.currentPage = 1;
          this.getRecruit();
          this.PostDialogVisible = false;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    getMoney(i, n) {
      console.log(i, n);
      let str = i / 1000 / 100 + "k" + "-" + n / 1000 / 100 + "k";
      return str;
    },
    editvideo(item) {
      this.videoDialogVisible = true;
      this.videoFrom = JSON.parse(JSON.stringify(item));
      this.videoFrom.url = "";
      this.fileList1.push({
        name: "",
        url: this.videoFrom.cover,
      });
    },
    addVideo() {
      insertCompanyVideo(this.videoFrom).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功！");
          this.getInfo();
          this.videoDialogVisible = false;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    daleteVideo(id) {
      delCompanyVideo({ videoId: id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
          this.getInfo();
        }
      });
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    handleSuccessCover(res, file, fileList) {
      this.fileList1 = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.videoFrom.cover = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemoveCover(file, fileList) {
      console.log(file, fileList);
      this.fileList1 = [];
      this.videoFrom.cover = "";
    },
    handleSuccessBanner(res, file, fileList) {
      this.fileList = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });

      this.fileList[
        this.fileList.length - 1
      ].url = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.videoFrom.url = `https://cdn-oss.jquen.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 30;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 30MB!");
      }
      return isLt2M;
    },
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
      if (this.activeName == "five") {
        this.getRecruit();
      } else if (this.activeName == "six") {
        let map = new BMap.Map("baidumap");
        ac = new BMap.Autocomplete({
          //建立一个自动完成的对象
          input: "suggestId",
          location: map,
        });
        console.log("1111111111111");
        ac.addEventListener("onconfirm", this.setValue);
      }
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.address = `${value.city}${value.district}${value.business}`;
    },
    baocun() {
      let arr = [];
      console.log(this.fileList, "this.fileList");
      let prams=JSON.parse(JSON.stringify(this.ruleForm));
      if (this.fileList.length > 0) {
        this.fileList.forEach((el) => {
          arr.push(el.url);
        });
        prams.photos = arr.join(",");
      } else {
        // delete prams.photos;
        prams.photos='';
      }
      console.log(prams, "提交*****");
      addWebsiteInfo(prams).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功！");
          this.getInfo();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    baocun1() {
      let arr = [];
      console.log(this.fileList, "this.fileList");
      if (this.fileList.length > 0) {
        this.fileList.forEach((el) => {
          arr.push(el.url);
        });
        this.ruleForm.photos = arr.join(",");
      } else {
        this.ruleForm.photos = "";
      }
      this.ruleForm.address = this.address;
      console.log(this.ruleForm, "提交*****");
      addWebsiteInfo(this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功！");
          this.getInfo();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    getRecruit() {
      this.loading = true;
      let data = {
        pageNum: this.currentPage,
      };
      selectCompanyRecruit(data).then((res) => {
        this.loading = false;
        console.log(res.data);
        this.tableData = res.data;
      });
    },
    getInfo() {
      let data = {
        ignore: true,
      };
      this.fileList = [];
      selectWebsiteInfo(data).then((res) => {
        this.ruleForm = res.data;
        let arr = this.ruleForm.photos.split(",");
        if (arr.length > 0) {
          arr.forEach((el) => {
            this.fileList.push({
              name: "",
              url: el,
            });
          });
        }
        console.log(this.ruleForm, "获取*****");
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .address-box {
//   width: 500px;
//   position: absolute;
//   left: 0px;
//   top: 0px;
//   z-index: 9999999;
//   opacity: 0;
// }

::v-deep .avatar-uploader .avatar-uploader-icon {
  font-size: 28px;
  line-height: 180px;
}

::v-deep.banner-img {
  .el-upload--picture-card {
    width: 200px;
    height: 160px;
    line-height: 160px;
  }

  .el-upload-list__item {
    width: 200px;
    height: 160px;
  }
}

::v-deep .disabled .el-upload--picture-card {
  display: none;
}
</style>